import React from 'react';

const LegalDocumentTemplate = (props) => {
  const legalContent = props.content;

  const renderSection = (section, index, level = 0) => (
    <div key={index} style={{ ...styles.sectionContainer, marginLeft: `${level * 10}px` }}>
      {section.title && <h2 style={{ ...styles.title, fontSize: `${24 - level * 8}px` }}>{section.title}</h2>}
      {section.content && <p style={styles.content}>{section.content}</p>}

      {section.list && (
        <div style={styles.listContainer}>
          {section.list.map((item, itemIndex) => (
            <div key={itemIndex} style={styles.listItem}>
              <span style={styles.listTerm}>
                <strong>- {item.term}</strong>
                {(item.term && item.description) && `:`}
                {item.description && ` ${item.description}`}
              </span>
              {item.list &&
                item.list.map((nestedListItem, nestedListItemIndex) => (
                  <div key={nestedListItemIndex} style={styles.listItem}>
                    <span style={styles.nestedListItem}>- {nestedListItem.description}</span>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}

      {section.subSections &&
        section.subSections.map((subSection, subIndex) => (
          <div key={subIndex}>
            {subSection.title && <h3 style={styles.subSectionTitle}>{subSection.title}</h3>}
            {subSection.content && <p style={styles.subSectionContent}>{subSection.content}</p>}

            {subSection.list && (
              <div style={styles.listContainer}>
                {subSection.list.map((item, itemIndex) => (
                  <div key={itemIndex} style={styles.listItem}>
                    <span style={styles.listTerm}>
                      <strong>- {item.term}</strong>
                      {(item.term && item.description) && `:`}
                      {item.description && ` ${item.description}`}
                    </span>
                    {item.list &&
                      item.list.map((nestedListItem, nestedListItemIndex) => (
                        <div key={nestedListItemIndex} style={styles.listItem}>
                          <span style={styles.nestedListItem}>- {nestedListItem.description}</span>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )}

            {subSection.content2 && <p style={styles.subSectionContent}>{subSection.content2}</p>}

            {subSection.subSections &&
              subSection.subSections.map((nestedSubSection, nestedIndex) => (
                <div key={nestedIndex} style={styles.nestedSubSectionContainer}>
                  {renderSection(nestedSubSection, nestedIndex, level + 1)}
                </div>
              ))}
          </div>
        ))}
    </div>
  );

  return (
    <div style={{ height: '100vh', overflowY: 'auto', userSelect: 'none' }}>
      <div style={styles.pressable}>
        <p style={styles.lastUpdated}>Last Updated: {legalContent.lastUpdated}</p>
        {legalContent.intro && <p style={styles.text}>{legalContent.intro}</p>}
        {legalContent.sections.map((section, index) => renderSection(section, index))}
      </div>
    </div>
  );
};

const styles = {
  sectionContainer: { marginBottom: '20px' },
  title: { fontWeight: 'bold', marginBottom: '10px' },
  content: { marginBottom: '10px' },
  listContainer: { paddingLeft: '20px', marginBottom: '10px' },
  listItem: { marginBottom: '5px' },
  listTerm: { display: 'block', marginBottom: '5px' },
  nestedListItem: { marginLeft: '20px', display: 'block' },
  subSectionTitle: { fontSize: '18px', marginTop: '10px', fontWeight: 'bold' },
  subSectionContent: { marginBottom: '10px' },
  nestedSubSectionContainer: { marginLeft: '20px', marginTop: '10px' },
  lastUpdated: { fontStyle: 'italic', marginBottom: '10px' },
  text: { marginBottom: '20px' },
  pressable: { cursor: 'pointer', padding: '10px' },
};

export default LegalDocumentTemplate;