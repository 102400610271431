import React, { useRef } from 'react';
import '../../src/App.css';
import racebaseLogo from '../racebaseLogo_symbol_white.png';
import racebaseText from '../racebaseText_whiteText.png';
import LegalDocumentTemplate from '../components/LegalDocumentTemplate';
import { privacyPolicy } from '../documents/privacyPolicy';

const PrivacyPolicy = () => {
  const logoRef = useRef(null)
  return (
    <div className="App">
      <header className="App-header">
        <img ref={logoRef} src={racebaseLogo} className="App-logo" alt="RACEBASE_LOGO" />
        <h1>Privacy Policy</h1>
        <div className="arrow-down"></div>
      </header>

      <LegalDocumentTemplate content={privacyPolicy} />
      
      <div className="bottom-corner">
        <img src={racebaseText} alt="RACEBASE" className="bottom-corner-img" />
        <p className="bottom-corner-text">2024</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;